import { Group } from './groups'

export type Category = {
    identifier: 'adults' | 'teens' | 'seniors' | 'single-access-drop-in' | 'punch-cards' | 'kids-dance' | '1-on-1' | 'small-group'
    title: string
    groups: Group['identifier'][]
}

const categories: Category[] = [
    {
        identifier: 'adults',
        title: 'Adults (18+)',
        groups: ['memberships']
    },
    {
        identifier: 'teens',
        title: 'Teens (13-17)',
        groups: ['memberships']
    },
    {
        identifier: 'seniors',
        title: 'Seniors (65+)',
        groups: ['memberships']
    },
    {
        identifier: 'single-access-drop-in',
        title: 'Single Drop-in',
        groups: ['drop-ins']
    },
    {
        identifier: 'punch-cards',
        title: 'Punch Cards',
        groups: ['drop-ins']
    },
    {
        identifier: 'kids-dance',
        title: 'Winter Program',
        groups: ['kids-dance']
    },
    {
        identifier: '1-on-1',
        title: 'One on one',
        groups: ['personal-training']
    },
    {
        identifier: 'small-group',
        title: 'Small Group',
        groups: ['personal-training']
    }
]

export default categories